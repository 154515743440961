<!--

	Pagination component
	Displays Numbered Links
	Emits gotoPage()

	<MhPagination
		:currentPage="1"
		:paginationLinks="[
			"http://lubok.local:8080/wp-json/mh/v1/lubok__products?filterSlug=0&perPage=5&paged=1",
			"http://lubok.local:8080/wp-json/mh/v1/lubok__products?filterSlug=0&perPage=5&paged=2",
			"http://lubok.local:8080/wp-json/mh/v1/lubok__products?filterSlug=0&perPage=5&paged=3",
			"http://lubok.local:8080/wp-json/mh/v1/lubok__products?filterSlug=0&perPage=5&paged=4"
		]">
	</MhPagination>

	Props:
		currentPage			Number
		paginationLinks		Array with urls

	Events:
		gotoPage( newPageNumber )

	TODO:
		Buttons von BaseButton einbinden
		Rename from "mhPagination" to "MhPagination"

	2019-06-06	init

-->

<template>
	<div class="mhPagination" :class="'mhPagination--countPages-' + countPages">
		<div class="mhPagination__inner font font--sizeMedium">
			<template v-if="showPrevLink">
				<a 	class="mhPagination__item mhPagination__link mhPagination__link--arrow mhPagination__link--prev"
					@click="$emit('gotoPage', currentPage-1)">
					<span>←</span>
				</a>
			</template>
			<span class="mhPagination__numbers">
				<a 	class="mhPagination__item mhPagination__link mhPagination__link--number"
					:class="getItemClasses(link, index)"
					@click="$emit('gotoPage', index+1)"
					v-for="(link, index) in paginationLinks" :key="index">
						<span>{{index+1}}</span>
				</a>
			</span>
			<template v-if="showNextLink">
				<a 	class="mhPagination__item mhPagination__link mhPagination__link--arrow mhPagination__link--next"
					@click="$emit('gotoPage', currentPage+1)">
					<span>→</span>
				</a>
			</template>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	//import BaseButton from '@/components/Base/BaseButton.vue'

	export default {
		name: 'MhPagination',
		components: {},
		mixins: [],
		props: {
			currentPage: [Number],
			paginationLinks: [Array],
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			countPages(){
				if( this._.isArray( this.paginationLinks ) ){
					return this.paginationLinks.length
				}else{
					return 0
				}
			},
			showPrevLink(){
				if( this.paginationLinks &&
					this.paginationLinks.length &&
					this.currentPage > 1 ){
					return true
				}
			},
			showNextLink(){
				if( this.paginationLinks &&
					this.paginationLinks.length &&
					this.currentPage < this.paginationLinks.length ){
					return true
				}
			},
		},
		methods: {
			getItemClasses(link, index){
				let classes = []

				if( this.currentPage == index+1 ){
					classes.push('mhPagination__link--isActive')
				}

				return classes
			},
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../../less/vars.less";
	@import "../../less/mixins.less";

	.mhPagination {
		//outline: 1px solid fade(red, 20);
		display: flex;
		font-weight: bold;

		@item : {
			height: 2.5em;
		}

		&__inner {
			display: flex;
		}

		&__numbers {
			display: flex;
		}
		&__link {
			transition: opacity 0.2s ease;
			position: relative;
			display: block;
			//padding: 0.5em 0.8em;
			opacity: 0.35;
			color: inherit;
			margin-right: -1px;
		}
		&__link--arrow,
		&__link--number {
			height: @item[height];
			width: @item[height];
			line-height: @item[height];
			text-align: center;
		}
		&__link::after {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			border: 1px solid;
			content: "";
		}
		&__link:not(:last-child):not(:hover):not(&__link--isActive)::after {
			border-right: none;
		}
		&__link--isActive {
			opacity: 1;
			&::after { border-width: 2px; }
		}
		&__link:not(&__link--isActive):hover {
			opacity: 0.75;
		}

		// hide pagination if there's just one page
		&--countPages-1 {
			display: none;
		}

		// context specific
		&:not(&--shop) {
			.mhPagination__link--isActive {
				background-color: black;
				span { color: white; }
			}
		}
		&--shop {
			.mhPagination__link--isActive {
				background-color: white;
				span { color: black; }
			}
		}

	}
</style>
