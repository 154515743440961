<template>
	<div class="LubokEventsView view" v-if="viewIsReady">
		<LuMobileHeader
			class="app__mobileNav"
			v-if="$root.isSmallDevice"
		></LuMobileHeader>

		<div class="grid grid--pageTemplateForEvents hSpace hSpace--page vSpace vSpace--page maxWidth maxWidth--page"
			 :class="{ 'vSpace vSpace--page' : $root.isSmallDevice }">
			<!-- header + aside (both sticky) -->
			<div>
				<LuPageHeader
					:title="$route.name=='EventsView' ? $t('title.currentEvents') : $t('title.pastEvents')"
				></LuPageHeader>
			</div>
			<!-- content: events -->
			<div>
				<div v-for="post in posts" :key="post.id">
					<LuHeadline
						class="color XXcolor--award"
						:text="$root.getTranslatedAcfValue( post.acf, 'postTitle' )"
						:subText="$root.getTranslatedAcfValue( post.acf, 'location' )"
						:hasLink="true"
						:linkUrl="'/' + $root.currentLang + '/events/' + post.slug + '/'"
						:hasAnchor="false"
						:startDate="post.acf.startDate"
						:endDate="post.acf.endDate"
						:lineAbove="true"
					></LuHeadline>

					<div
						v-for="(contentModule, index) in $root.getContentModules( post )"
						:key="index"
						v-bind="contentModule"
						:is="'Lu' + _.upperFirst(contentModule.acf_fc_layout)"
					></div>

					<LuSpacer
						:lines="2"
					></LuSpacer>
				</div>
			</div>
			<!-- spacer -->
			<div></div>
			<!-- footer: pagination -->
			<div :class="{ 'vSpace vSpace--pageBottom' : !$root.isSmallDevice }">

				<template v-if="paginationLinks && paginationLinks.length > 1">
					<div class="border border--bottom border--strong"></div>
				</template>

				<MhPagination
					class="mhPagination--events vSpace vSpace--pagination"
					@gotoPage="addPagedQuery"
					:currentPage="currentPage"
					:paginationLinks="paginationLinks">
				</MhPagination>

				<LuSpacer
					:lines="5"
				></LuSpacer>
			</div>
		</div>

		<!--
		<pre data-name="posts">{{posts}}</pre>
		<pre data-name="totalPosts">{{totalPosts}}</pre>
		<pre data-name="totalPages">{{totalPages}}</pre>
		<pre data-name="currentPage">{{currentPage}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '@/mixins/restHandler/v1/restHandler.mixin.js'
	import ViewMixin from './View.mixin.js'
	import MhPagination from '@/components/MhPagination/MhPagination.vue'
	import MhDevGrid from '@/components/MhDevGrid/MhDevGrid.vue'
	import MhSmartHeader from '@/components/MhSmartHeader/MhSmartHeader.vue'

	import LuMobileHeader from '@/components/LuMobileHeader.vue'
	import LuPageHeader from '@/components/LuPageHeader.vue'
	import LuHeadline from '@/components/LuHeadline.vue'
	import LuText from '@/components/LuText.vue'
	import LuGallery from '@/components/LuGallery.vue'
	import LuSpacer from '@/components/LuSpacer.vue'

	export default {
		name: 'LubokEventsView',
		components: {
			MhDevGrid,
			MhPagination,
			MhSmartHeader,
			LuMobileHeader,
			LuPageHeader,
			LuHeadline,
			LuText,
			LuGallery,
			LuSpacer,
		},
		mixins: [
			restHandler,
			ViewMixin,
		],
		props: {},
		data() {
			return {
				paginationLinks : null,
				currentPage     : null,
				posts           : [],
			}
		},
		watch: {
			'$route.query.paged': {
				handler: function( to, from ) {
					const paged = to ? to : 1

					this.doFetch( paged )
				},
				deep: true,
			},
		},
		computed: {},
		methods: {
			addPagedQuery( paged ){
				const routeName = this.$route.name
				this.$router.push({ name: this.$route.name, query: { paged : paged } })
			},
			doFetch( paged ) {

				let params = {
					perPage : 30, // TODO:5710 set higher, its just low to show the pagination
					paged   : paged,
					range   : this.$route.name == 'EventsView' ? 'nowAndFuture' : 'past'
				}

				// send loading to MhView to show the spinner
				EventBus.$emit('stateChanged', 'loading')

				// Setze Param für Aktuelle oder vergangene Events
				let rangeParam = ''
				if( this.$route.name == 'EventsView' ) rangeParam = 'nowAndFuture'
				if( this.$route.name == 'EventsArchiveView' ) rangeParam = 'past'

				// fetch events
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/lubok__events',
					params : params,
					callback : (response) => {
						this.currentPage     = response.data.info.currentPage
						this.paginationLinks = response.data.info.paginationLinks
						this.posts           = response.data.result

						this.viewIsReady = true	// this comes from View.mixin.js

						// send loaded to MhView to hide the spinner again
						EventBus.$emit('stateChanged', 'loaded')
					},
				})
			}
		},
		created() {},
		mounted() {
			// if ?paged= use this otherwise 1
			const paged = this.$route.query.paged ? this.$route.query.paged : 1

			this.doFetch( paged )

			if( this.$route.name == 'EventsView' ) this.title = this.$t('nav.currentEvents') + ' ' + this.$t('nav.events')
			if( this.$route.name == 'EventsArchiveView' ) this.title = this.$t('nav.pastEvents') + ' ' + this.$t('nav.events')
		},
	}
</script>

<style lang="less">
	.LubokEventsView {
		//opacity: 0.1;
	}
</style>
